<template>
<div class="wrap h-box-shadow" v-loading="loading">
  <el-form
    v-show="step === 1"
    ref="fm"
    :model="fm"
    class="fm"
    label-width="190px">
    <div class="section">
      <div class="section-head">
        参考文献信息
      </div>
      <div class="section-body">
        <el-row :gutter="20">
          <!-- ...其他表单项... -->
          <el-col :span="16">
            <el-form-item label="DOI or URL" prop="DOI_or_URL">
              <template slot="label">{{$t('model.sample.DOI_or_URL')}}</template>
              <el-input v-model="fm.DOI_or_URL" placeholder="请输入内容"> <template slot="append">样例:10.1007/s00531-017-1455-z</template></el-input>
          
            </el-form-item>
          </el-col>
          <el-col :span="8">


            
            <div style="display: flex; align-items: center;">
              <el-tooltip class="item" effect="dark" content="根据DOI自动获取" placement="top-start">
                <el-button type="primary" icon="el-icon-s-platform" @click="parseDOI">根据DOI自动获取</el-button>
              </el-tooltip>
              <el-tooltip class="item" effect="dark" content="对文献进行查重" placement="top-start">
                <el-button type="primary" icon="el-icon-s-order"  @click="wxCheckIsRepeat">查重</el-button>
              </el-tooltip>
            </div>


            <!-- <el-form-item prop="parseDOI">
              <el-button type="primary" @click="parseDOI">根据DOI自动获取</el-button>
            </el-form-item>
            <el-form-item prop="parseDOI">
              <el-button type="primary" @click="wxCheckIsRepeat">查重</el-button>
            </el-form-item> -->
          </el-col>
          <!-- ...其他表单项... -->
        </el-row>
        <el-row :gutter="20">
          <el-col :span="8">
            <el-form-item label="第一作者姓名" prop="First_Author" required>
              <template slot="label">{{$t('model.sample.First_Author')}}</template>
              <el-input v-model="fm.First_Author"></el-input>
            </el-form-item>
          </el-col>
          <!-- <el-col :span="8">
            
            <el-form-item label="DOI or URL" prop="DOI_or_URL">
              <template slot="label">{{$t('model.sample.DOI_or_URL')}}</template>
              <el-input v-model="fm.DOI_or_URL"></el-input>
              <el-button type="primary" @click="parseDOI">解析填充DOI</el-button>
            </el-form-item>
          

          </el-col> -->
          <el-col :span="8">
            <el-form-item label="刊物名" prop="Journal" required>
              <template slot="label">{{$t('model.sample.Journal')}}</template>
              <el-input v-model="fm.Journal"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="题名" prop="Title" required>
              <template slot="label">{{$t('model.sample.Title')}}</template>
              <el-input v-model="fm.Title"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="出版年" prop="Year" required>
              <template slot="label">{{$t('model.sample.Year')}}</template>
              <el-input v-model="fm.Year"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="卷" prop="Volume">
              <template slot="label">{{$t('model.sample.Volume')}}</template>
              <el-input v-model="fm.Volume"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="期" prop="Issue">
              <template slot="label">{{$t('model.sample.Issue')}}</template>
              <el-input v-model="fm.Issue"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="页码" prop="Pages">
              <template slot="label">{{$t('model.sample.Pages')}}</template>
              <el-input v-model="fm.Pages"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="文章号" prop="Article_Number">
              <template slot="label">{{$t('model.sample.Article_Number')}}</template>
              <el-input v-model="fm.Article_Number"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="文献类型" prop="Reference_type">
              <template slot="label">{{$t('model.sample.Reference_type')}}</template>
              <!-- <el-input v-model="fm.Reference_type"></el-input> -->
              <el-select clearable filterable v-model="fm.Reference_type">
                <el-option label="Journal article" value="Journal article"></el-option>
                <el-option label="Thesis" value="Thesis"></el-option>
                <el-option label="Monograph" value="Monograph"></el-option>
                <el-option label="Conference paper" value="Conference paper"></el-option>
                <el-option label="Other" value="Other"></el-option>
              </el-select>

            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="语言" prop="Language">
              <template slot="label">{{$t('model.sample.Language')}}</template>
              <!-- <el-input v-model="fm.Language"></el-input> -->
              <el-select clearable filterable v-model="fm.Language">
                <el-option label="Chinese" value="Chinese"></el-option>
                <el-option label="English" value="English"></el-option>
                <el-option label="Other" value="Other"></el-option>
              </el-select>

            </el-form-item>
          </el-col>
        </el-row>
      </div>
    </div>
    <div class="section section--pos">
      <div class="section-head">
        样品与地点
      </div>
      <div class="section-body">
        <el-row :gutter="20">
          <el-col :span="24">
            <!-- <el-form-item prop="Sample_Name" >
              <template slot="label">{{$t('model.sample.Sample_Name')}}</template>
              <el-input v-model="fm.Sample_Name"></el-input>
            </el-form-item> -->

            <el-form-item label="样品编号" prop="Sample_Name" required>
              <el-input v-model="fm.Sample_Name"></el-input>
            </el-form-item>
            
          </el-col>
          <el-col :span="12">
            <el-form-item prop="Continent_or_Ocean">
              <template slot="label">{{$t('model.sample.Continent_or_Ocean')}}</template>
              <el-select clearable filterable 
                v-model="fm.Continent_or_Ocean" 
                @change="onContinetOrOceanChange">
                <el-option
                  v-for="item in dict.Continent_or_Ocean"
                  :key="item.id"
                  :label="item.value"
                  :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="纬度" prop="Longitude">
              <template slot="label">{{$t('model.sample.Longitude')}}</template>
              <el-input v-model="fm.Longitude"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="国家" prop="Country" required>
              <template slot="label">{{$t('model.sample.Country')}}</template>
              <el-select clearable filterable   v-model="fm.Country">
                <el-option
                  v-for="item in dict.Country"
                  :key="item.id"
                  :label="item.value"
                  :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="经度" prop="Latitude">
              <template slot="label">{{$t('model.sample.Latitude')}}</template>
              <el-input v-model="fm.Latitude"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="具体位置" prop="Detailed_locality">
              <template slot="label">{{$t('model.sample.Detailed_locality')}}</template>
              <el-input v-model="fm.Detailed_locality"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="参考位置" prop="Detailed_localityck">
              <template slot="label">{{$t('model.sample.Detailed_localityck')}}</template>
              <el-input v-model="fm.Detailed_localityck"  :disabled="true" placeholder="点击地图自动获取地址" >
                <!-- <template slot="append"  @click="onfz">复制</template> -->
                <el-button type="primary" icon="el-icon-edit"></el-button>
              </el-input>


              <div style="display: flex; align-items: center;">
                <el-tooltip class="item" effect="dark" content="(参考位置)复制到(具体位置)" placement="top-start">
                  <el-button type="primary" icon="el-icon-edit" @click="onfz">复制</el-button>
                </el-tooltip>
                <el-tooltip class="item" effect="dark" content="录入经纬度后，在地图上定位" placement="top-start">
                  <el-button type="primary" icon="el-icon-location"  @click="locateOnMap">定位</el-button>
                </el-tooltip>
              </div>

  
          
            </el-form-item>
          </el-col>
          <!-- <el-col :span="12">
            <el-form-item label="海拔" prop="Elevation">
              <template slot="label">{{$t('model.sample.Elevation')}}</template>
              <el-input-number v-model="fm.Elevation"></el-input-number>
            </el-form-item>
          </el-col> -->
          
        </el-row>
        <div class="map">
          <div class="map-content" ref="mapContent"></div>
        </div>
      </div>
    </div>
    <div class="section">
      <div class="section-head">
        地质背景
      </div>
      <div class="section-body">
        <el-row :gutter="20">
          <el-col :span="8">
            <el-form-item label="地体" prop="Terrane">
              <template slot="label">{{$t('model.sample.Terrane')}}</template>
              <el-input v-model="fm.Terrane"></el-input>
            </el-form-item>
          </el-col>
          <!-- <el-col :span="8">
            <el-form-item label="钻孔名称" prop="Drilling_name">
              <template slot="label">{{$t('model.sample.Drilling_name')}}</template>
              <el-input v-model="fm.Drilling_name"></el-input>
            </el-form-item>
          </el-col> -->
          <!-- 地质时代 -->
          <el-col :span="8">
            <el-form-item label="地质时代" prop="Geological_period">
              <template slot="label">{{$t('model.sample.Geological_period')}}</template>
              <el-select clearable filterable v-model="fm.Geological_period">
                <el-option
                  v-for="item in dict.Geological_period"
                  :key="item.key"
                  :label="item.value"
                  :value="item.key">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <!-- 动力学背景 -->
          <el-col :span="8">
            <el-form-item label="动力学背景" prop="Geodynamic_settings">
              <template slot="label">{{$t('model.sample.Geodynamic_settings')}}</template>
              <el-select clearable filterable v-model="fm.Geodynamic_settings">
                <el-option
                  v-for="item in dict.Geodynamic_settings"
                  :key="item.key"
                  :label="item.value"
                  :value="item.key">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <!-- <el-col :span="8">
            <el-form-item label="钻孔深度" prop="Drilling_depth">
              <template slot="label">{{$t('model.sample.Drilling_depth')}}</template>
              <el-input v-model="fm.Drilling_depth"></el-input>
            </el-form-item>
          </el-col> -->
          <el-col :span="8">
            <el-form-item label="地质时代最小值" prop="">
              <template slot="label">{{$t('model.sample.Min_age_limit')}}</template>
              <el-input :controls='false' v-model="fm.Min_age_limit"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="岩石或地层单元" prop="Rock_or_stratigraphic_unit">
              <template slot="label">{{$t('model.sample.Rock_or_stratigraphic_unit')}}</template>
              <el-input v-model="fm.Rock_or_stratigraphic_unit"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="造岩矿物" prop="Rock_forming_minerals">
              <template slot="label">{{$t('model.sample.Rock_forming_minerals')}}</template>
              <el-input v-model="fm.Rock_forming_minerals"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="地质时代最大值" prop="Max_age_limit">
              <template slot="label">{{$t('model.sample.Max_age_limit')}}</template>
              <el-input :controls='false' v-model="fm.Max_age_limit"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="岩性大类" prop="Lithology" required>
              <template slot="label">{{$t('model.sample.Lithology')}}</template>
              <el-select clearable filterable v-model="fm.Lithology">
                <el-option
                  v-for="item in dict.Lithology"
                  :key="item.key"
                  :label="item.value"
                  :value="item.key">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="岩性小类" prop="Lithology_subdivision">
              <template slot="label">{{$t('model.sample.Lithology_subdivision')}}</template>
              <el-input v-model="fm.Lithology_subdivision"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="变质级别" prop="Metamorphic_grade">
              <template slot="label">{{$t('model.sample.Metamorphic_grade')}}</template>
              <el-input v-model="fm.Metamorphic_grade"></el-input>
            </el-form-item>
          </el-col>
          <!-- <el-col :span="8">
            <div style="height: 64px;"></div>
          </el-col> -->
       
          <el-col :span="8">
            <el-form-item label="沉积环境" prop="Sedimentary_environment">
              <template slot="label">{{$t('model.sample.Sedimentary_environment')}}</template>
              <el-select clearable filterable v-model="fm.Sedimentary_environment">
                <el-option
                  v-for="item in dict.Sedimentary_environment"
                  :key="item.key"
                  :label="item.value"
                  :value="item.key">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </div>
    </div>
    <div class="section">
      <div class="section-head">
        分析方法
      </div>
      <div class="section-body">
        <el-row :gutter="20">
          <el-col :span="8">
            <el-form-item label="测定对象或矿物" prop="Object_or_mineral_determined">
              <template slot="label">{{$t('model.sample.Object_or_mineral_determined')}}</template>
              <el-select clearable filterable v-model="fm.Object_or_mineral_determined">
                <el-option
                  v-for="item in dict.Object_or_mineral_determined"
                  :key="item.key"
                  :label="item.value"
                  :value="item.key">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="测试机构" prop="Place_for_experiment">
              <template slot="label">{{$t('model.sample.Place_for_experiment')}}</template>
              <el-input v-model="fm.Place_for_experiment"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <div style="height: 64px;"></div>
          </el-col>
          <el-col :span="8">
            <el-form-item label="测试仪器" prop="Instrument_for_analysis">
              <template slot="label">{{$t('model.sample.Instrument_for_analysis')}}</template>
              <el-select clearable filterable v-model="fm.Instrument_for_analysis" multiple>
                <el-option
                  v-for="item in dict.Instrument_for_analysis"
                  :key="item.key"
                  :label="item.value"
                  :value="item.key">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="测试内容" prop="Analytical_content">
              <template slot="label">{{$t('model.sample.Analytical_content')}}</template>
              <el-select clearable filterable v-model="fm.Analytical_content" multiple>
                <el-option
                  v-for="item in dict.Analytical_content"
                  :key="item.key"
                  :label="item.value"
                  :value="item.key">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </div>
    </div>
    <div class="section">
      <div class="section-head">
        年龄结果
      </div>
      <div class="section-body">
        <el-row :gutter="20">
          <el-col :span="8">
            <el-form-item label="表达方式" prop="Expression">
              <template slot="label">{{$t('model.sample.Expression')}}</template>
              <el-select clearable filterable v-model="fm.Expression">
                <el-option
                  v-for="item in dict.Expression"
                  :key="item.key"
                  :label="item.value"
                  :value="item.key">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="年龄值" prop="Age">
              <template slot="label">{{$t('model.sample.Age')}}</template>
              <el-input :controls='false' v-model="fm.Age">
                <template slot="append">Ma</template>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <div style="height: 64px;"></div>
          </el-col>
          <el-col :span="8">
            <el-form-item label="2s(Ma)" prop="SE2">
              <template slot="label">{{$t('model.sample.SE2')}}</template>
              <el-input :controls='false' v-model="fm.SE2">
                <template slot="append">Ma</template>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="年龄解释" prop="Interpretation">
              <template slot="label">{{$t('model.sample.Interpretation')}}</template>
              <el-select clearable filterable v-model="fm.Interpretation">
                <el-option
                  v-for="item in dict.Interpretation"
                  :key="item.key"
                  :label="item.value"
                  :value="item.key">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </div>
    </div>
    <div class="foot-action">
      <el-button type="primary" @click="submit">保存</el-button>
    </div>
  </el-form>
  <div v-show="step === 2" class="section">
    <div class="section-head">
      导入关联数据
    </div>
    <div class="section-body" style="padding-bottom: 30px;">
      <template v-if="isImportSuccess">
        <el-result icon="success" title="成功" subTitle="导入关联数据成功">
          <template slot="extra">
            <el-button type="primary" @click="reset">返回</el-button>
          </template>
        </el-result>
      </template>
      <template v-else>
        <div class="action-row">
          <el-button
            type="primary"
            @click="onDownloadImportTpl"
            style="margin-right: 8px;">
            {{ $t('action.downloadImportTpl') }}
          </el-button>
          <el-upload
            name="fileInput"
            :headers="{
              Authorization: token ? 'Bearer ' + token : ''
            }"
            :show-file-list="false"
            :action="importData.url"
            accept=".xlsx,.xls"
            :on-progress="onImportDataProgress"
            :on-error="onImportDataError"
            :on-success="onImportDataSuccess"
            class="upload-button">
            <el-button slot="trigger" type="primary">{{ $t('action.importData') }}</el-button>
          </el-upload>
        </div>
        <template>
          <el-alert
            title="导入关联数据流程"
            type="success"
            :closable="false"
            description="1.下载模版填写数据 2.保存当前数据(显示“导入”按钮)，3.导入关联数据"
            style="margin-bottom: 8px;">
          </el-alert>
          <el-alert
            title="模版填写提示"
            type="success"
            :closable="false"
            description="模版内定年分析(sheet)、同位素分析(sheet)、主量元素分析(sheet)、微量元素分析(sheet),根据实际情况填写，可以任选一个或多个(为避免脏数据入库，上传关联数据里的样品编号要和保存的样品编号一致)">
          </el-alert>
        </template>
      </template>
    </div>
  </div>
</div>
</template>

<script>
import { mapState } from 'vuex'
import  * as dSampleApi from '@/api/dSample'
import commonApi from '@/api/common'
import L from 'leaflet';
import 'leaflet/dist/leaflet.css'
import axios from 'axios';
import setting from '@/setting'
export default {
  data() {
    return {
      step: 1, // 1基础信息录入 2导入关联数据
      isImportSuccess: false, // 继续录入按钮是否显示
      rules: {
      Sample_Name: [
        { required: true, message: '样品编号是必填项', trigger: 'blur' }
      ]
    },
      sampleId: null, // Sample_ID
      loading: false,
      dict: {
        Continent_or_Ocean: [], // 大陆或大洋
        Country: [], // 国家
        Geodynamic_settings: [], // 地球动力学背景
        Geological_period: [], // 地质时代
        Lithology: [], // 岩性大类
        Sedimentary_environment: [], // 沉积环境
        Object_or_mineral_determined: [], // 测定对象或矿物
        Instrument_for_analysis: [], // 测试仪器
        Analytical_content: [], // 测试内容
        Expression: [], // 表达方式
        Interpretation: [], // 年龄解释
      },
      fm: {
        Sample_Name: '',
        Continent_or_Ocean:'',
        Country: '',
        Detailed_locality: '',
        Detailed_localityck: '',
        Latitude: '',
        Longitude: '',
        Elevation: '',
        Terrane: '',
        Geodynamic_settings: '',
        Rock_or_stratigraphic_unit: '',
        Drilling_name: '',
        Drilling_depth: '',
        Geological_period: '',
        Min_age_limit: '',
        Max_age_limit: '',
        Lithology: '',
        Lithology_subdivision: '',
        Metamorphic_grade: '',
        Sedimentary_environment: '',
        Rock_forming_minerals: '',
        Object_or_mineral_determined: '',
        Reference_type: '',
        Language: '',
        Journal: '',
        Title: '',
        Year: '',
        Volume: '',
        Issue: '',
        Pages: '',
        Article_Number: '',
        First_Author: '',
        DOI_or_URL: '',
        Place_for_experiment: '',
        Instrument_for_analysis: '',
        Analytical_content: '',
        Expression: '',
        Age: '',
        SE2: '',
        Interpretation: '',
        First_Author: '',
        DOI_or_URL: '',
        Journal: '',
        Title: '',
        Year: '',
        Volume: '',
        Issue: '',
        Pages: '',
        Article_Number: '',
        Reference_type: '',
        Language: '',
      },
      tree_Continent: [], // 大洲国家关系平铺数据
      importData: {
        url: `${setting.baseUrl.api}D_Sample/Import_zplr?getid=${this.sampleID}`,
        loading: false
      },
      importDataadd_check_count: {
        //(string DOI_or_URL, string First_Author,string Journal, string Title, string Year, string Volume, string Issue, string Pages)
        url: `${setting.baseUrl.api}D_Sample/add_check_count?DOI_or_URL=${this.DOI_or_URL}First_Author=${this.First_Author}Journal=${this.Journal}Title=${this.Title}Year=${this.Year}Volume=${this.Volume}Issue=${this.Volume}Pages=${this.Pages}`,
        loading: false
      },
    }
  },
  computed: {
    ...mapState(['user', 'token'])
  },
  methods: {
  
    onDownloadImportTpl(val) {
      let url = location.origin + '/static/逐篇录入-关联数据模版.xlsx'
      window.open(url, '_blank')
    },
    onImportDataProgress() {
      this.importData.loading = true
    },
    onImportDataError(res) {
      console.log(res)
      this.importData.loading = false
      this.$message.error({
        message: res.message
      })
    },
    onImportDataSuccess(res, file) {
      this.importData.loading = false
      if (res.status) {
        this.isImportSuccess = true
        this.$message.success({
          message: '导入成功！'
        })
      } else {
        this.$message.error({
          message: res.message
        })
      }
    },
    async parseDOI() {
      const doi = this.fm.DOI_or_URL;
      if (!doi) {
        this.$message.error('DOI不能为空');
        return;
      }

      try {
        //10.1007/s00531-017-1455-z
        const response = await axios.get(`https://api.crossref.org/works/${encodeURIComponent(doi)}`);
        const data = response.data.message;
        if (data.title) {
          this.fm.First_Author = this.getFirstAuthor(data.author);
          this.fm.DOI_or_URL = doi; // 通常不需要填充，因为用户已经输入
          this.fm.Journal = data['container-title'][0];
          this.fm.Title = data.title[0];
          this.fm.Year = data.published && data.published['date-parts'] && data.published['date-parts'][0] && data.published['date-parts'][0][0];
          this.fm.Volume = data.volume;
          this.fm.Issue = data.issue;
          this.fm.Pages = data.page;
          this.fm.Article_Number = data['article-number'] || data.supplement || data.coden || '';; // 文章号
          this.fm.Reference_type = data.type || ''; // 文献类型
          this.fm.Language = data.language || ''; // 语言
          // ...填充其他字段...
          this.$message.success('文献信息填充成功');
        } else {
          this.$message.error('无法获取文献信息');
        }
      } catch (error) {
        this.$message.error('未找到');
        console.error('Error:', error);
      }
    },


    async wxCheckIsRepeat() {

      if (!this.fm.DOI_or_URL) {
        this.$message.error('DOI_or_URL');
        return;
      }
      if (!this.fm.First_Author) {
        this.$message.error('第一作者名');
        return;
      }
      if (!this.fm.Journal) {
        this.$message.error('刊物名');
        return;
      }
      if (!this.fm.Title) {
        this.$message.error('题名');
        return;
      }
      if (!this.fm.Year) {
        this.$message.error('出版年');
        return;
      }
      try {
        const { status, msg } = await dSampleApi.wxCheckIsRepeat({
          DOI_or_URL: this.fm.DOI_or_URL,
          First_Author: this.fm.First_Author,
          Journal: this.fm.Journal,
          Title: this.fm.Title,
          Year: this.fm.Year,
          Volume: this.fm.Volume,
          Issue: this.fm.Issue,
          Pages: this.fm.Pages,
        })
        if (status) { // true:有重复 false:无重复
          this.$message.error(msg)
        } else {
          this.$message.success('未发现重复数据')
        }

      } catch (error) {
        this.$message.error('查重失败');
        console.error('Error:', error);
      }
    },
    getFirstAuthor(authors) {
      if (!authors || authors.length === 0) return '';
      const firstAuthor = authors.find(author => author['family']);
      return firstAuthor ? `${firstAuthor['given']} ${firstAuthor['family']}` : '';
    },
    initMap() {
    this.map = L.map(this.$refs.mapContent).setView([39.9042, 116.4074], 8);
    L.tileLayer('http://{s}.tianditu.gov.cn/DataServer?T=vec_w&x={x}&y={y}&l={z}&tk=1d587a982d803b2a9f1ad58f2764e29e', {
      subdomains: ['t0', 't1', 't2', 't3', 't4', 't5', 't6', 't7'],
      attribution: '岩石地球化学数据中心'
    }).addTo(this.map);

    this.map.on('click', (e) => {
      const lat = e.latlng.lat;
      const lon = e.latlng.lng;

      if (this.currentMarker) {
        this.map.removeLayer(this.currentMarker);
      }
      this.currentMarker = L.marker([lat, lon]).addTo(this.map);

      this.fetchAddress(lat, lon);
    });
  },
  fetchAddress(lat, lon) {
    const url = `http://api.tianditu.gov.cn/geocoder?postStr={'lon': ${lon}, 'lat': ${lat}, 'ver': 1}&type=geocode&tk=1d587a982d803b2a9f1ad58f2764e29e`;
    fetch(url)
      .then(response => response.json())
      .then(data => {
        if (data.status === "0") {
          var getadd =data.result.formatted_address;
          if(getadd=='')
          {
            getadd= data.result.addressComponent.nation+data.result.addressComponent.address
          }
          const address = getadd;
          this.fm.Detailed_localityck = address; // 填充具体位置
          this.fm.Latitude = lat; // 填充纬度
          this.fm.Longitude = lon; // 填充经度
        } else {
          console.error('Error fetching address');
        }
      })
      .catch(error => {
        console.error('Error:', error);
      });
  },

  locateOnMap() {
    if (this.fm.Latitude && this.fm.Longitude) {
      this.map.setView([this.fm.Latitude, this.fm.Longitude], 8);
      if (this.currentMarker) {
        this.map.removeLayer(this.currentMarker);
      }
      this.currentMarker = L.marker([this.fm.Latitude, this.fm.Longitude]).addTo(this.map)
        .bindPopup('手动定位,录入地点')
        .openPopup();
    } else {
      this.$message.error('请先录入经纬度');
    }
  },

    onfz() {

      console.log("this.fm.Detailed_localityck",this.fm.Detailed_localityck);
      if(this.fm.Detailed_localityck =='')
      {
        this.$message.error('先点击地图获取参考位置，然后再点击复制到具体位置');
      }
      else
      {
        this.fm.Detailed_locality =this.fm.Detailed_localityck 
      }
    },

    // ... 省略其他方法 ...

    async getDict() {
      const allOpt = { DicValue: '', DicName: '全部' }

      // 大陆或大洋
      const dicts = await commonApi.getVueDictionary([
        'LIstContinent',
        'ListCountry',
        'list_Geodynamic Settings',
        'List_GeologicalPeriods',
        'list_Lithology',
        'list_Metamorphic grade',
        'list_Sedimentary_environment',
        'list_Object_or_mineral_determined',
        'list_Instrument_for_analysis',
        'list_Analytical_content',
        'list_Expression',
        'list_Interpretation',
        'audit',
        'Data_DataType',
        'Data_DataStatus'
      ])

      this.dict.Continent_or_Ocean = dicts.find(item => item.dicNo === 'LIstContinent')?.data ?? []
      this.dict.Country = dicts.find(item => item.dicNo === 'ListCountry')?.data ?? []
      this.dict.CountryRaw = dicts.find(item => item.dicNo === 'ListCountry')?.data ?? []
      this.dict.Geodynamic_settings = dicts.find(item => item.dicNo === 'list_Geodynamic Settings')?.data ?? []
      this.dict.Geological_period = dicts.find(item => item.dicNo === 'List_GeologicalPeriods')?.data ?? []
      this.dict.Lithology = dicts.find(item => item.dicNo === 'list_Lithology')?.data ?? []
      this.dict.Sedimentary_environment = dicts.find(item => item.dicNo === 'list_Sedimentary_environment')?.data ?? []
      this.dict.Object_or_mineral_determined = dicts.find(item => item.dicNo === 'list_Object_or_mineral_determined')?.data ?? []
      this.dict.Instrument_for_analysis = dicts.find(item => item.dicNo === 'list_Instrument_for_analysis')?.data ?? []
      this.dict.Analytical_content = dicts.find(item => item.dicNo === 'list_Analytical_content')?.data ?? []
      this.dict.Expression = dicts.find(item => item.dicNo === 'list_Expression')?.data ?? []
      this.dict.Interpretation = dicts.find(item => item.dicNo === 'list_Interpretation')?.data ?? []
    },
    onContinetOrOceanChange(val) {
      if (val) {
        this.dict.Country = this.dict.CountryRaw.filter(item => item.parentId === val)
      } else {
        this.dict.Country = JSON.parse(JSON.stringify(this.dict.CountryRaw))
        this.fm.Country = ''
      }
    },
    reset() {
      this.formReset()
      this.step = 1
      this.isImportSuccess = false
    },
    formReset() {
      this.$refs.fm.resetFields()
    },
  //   showImportAlert() {
  //   // 这里可以自定义提示信息的显示方式
  //   this.importAlertVisible = true;
  // },
    async submit() {
   
      this.loading = true

      // 手动检查Sample_Name是否已填写
      if (!this.fm.Sample_Name) {
        this.$message.error('样品编号是必填项，请填写后再提交！');
        this.loading = false;
        return;
      }
      if (!this.fm.First_Author) {
        this.$message.error('第一作者名');
        this.loading = false;
        return;
      }
      if (!this.fm.Journal) {
        this.$message.error('刊物名');
        this.loading = false;
        return;
      }
      if (!this.fm.Title) {
        this.$message.error('题名');
        this.loading = false;
        return;
      }
      if (!this.fm.Year) {
        this.$message.error('出版年');
        this.loading = false;
        return;
      }
      if (!this.fm.Lithology) {
        this.$message.error('岩性大类');
        this.loading = false;
        return;
      }
      if (!this.fm.Country) {
        this.$message.error('国家');
        this.loading = false;
        return;
      }
      // 表单验证
      let valid = await this.$refs.fm.validate();
      if (!valid) {
        this.$message.error('表单验证失败，请检查必填项！');
        this.loading = false;
        return;
      }

      this.loading = false;

      let mainData = JSON.parse(JSON.stringify(this.fm))
      console.log('mainData:', mainData)
      mainData.Analytical_content = mainData.Analytical_content.join(',')
      mainData.Instrument_for_analysis = mainData.Instrument_for_analysis.join(',')
      // const { status } = await dSampleApi.add({
      //   mainData,
      //   detailData: null,
      //   delKeys: null
      // }).finally(() => {
      //   this.loading = false
      // })

      const response = await dSampleApi.add({
        mainData,
        detailData: null,
        delKeys: null
      }).finally(() => {
        this.loading = false
      });

      if (response.status) {
        // 保存成功

        // 解析响应数据
        const responseData = JSON.parse(response.data); 
        // 提取Sample_ID
        this.sampleId = responseData.data.Sample_ID; 
         // 设置可以导入的标志
        this.step = 2;

        console.log("sampleId", this.sampleId); 
        // 确保在这里更新 importData.url
        this.importData.url = `${setting.baseUrl.api}D_Sample/Import_zplr?getid=${this.sampleId}`;

        this.$message.success('保存成功,导入关联数据');
        // this.showImportAlert(); // 显示导入关联数据的提示
      } else {
        // 保存失败
        this.$message.error('保存失败');
      }

      // if (status) {
      //   this.$message({
      //     message: '录入成功',
      //     type: 'success'
      //   })
      //   this.formReset()
      // } else {
      //   this.$message({
      //     message: '录入失败',
      //     type: 'error'
      //   })
      // }
    }
  },
  mounted() {
    this.initMap();
    this.getDict();
    
   
  }
}
</script>

<style lang="less" scoped>
  .wrap {
    background-color: #fff;
    .top {
      padding: 16px;
      text-align: right;
    }
  }
  .fm {
    /deep/ .el-select {
      width: 100%;
    }
  }
  .area-selector-dialog {
    /deep/ .el-dialog__body {
      padding: 0;
    }
  }
  .section {
    margin-bottom: 20px;
    &-head {
      padding: 20px 20px;
      font-weight: bold;
    }
    &-body {
      padding: 0 20px;
    }
    &--pos {
      .section-body {
        display: flex;
        .el-row {
          flex: 1 1 auto;
        }
        .map {
          flex: 0 0 400px;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0 0 22px 32px;
          &-content {
            flex: 1 1 auto;
            height: 100%;
            background-color: #ccc;
          }
        }
      }
    }
  }
  .foot-action {
    padding: 20px;
    border-top: 1px solid #DCDFE6;
  }

 
.action-row {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 8px;
}

.action-row .el-button + .el-upload {
  margin-left: 100px !important; /* 增加间距，并确保规则优先级 */
}
</style>
